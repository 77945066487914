import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, Tooltip, Typography } from '@mui/material';

import { GameEventWithVersion, FlowWithVersion } from '@playq/octopus2-liveops';
import { ExperimentWithVersion } from '@playq/octopus2-analytics';
import { TextFilter } from '@playq/octopus-common';

import { GenericTable, ITableChangeQuery, ITableColumn } from '/shared/Table';
import { useGenericTablePersistedQuery, useTableQueryChange } from '/hooks';
import { IGenericTableEntity, PersistedQueryKeys, SyntheticEventWithMetaKey } from '/common/models';
import { navigateToURL } from '/helpers';
import { PageLoaderSpinner } from '/shared/Spinner';
import { snackbarService } from '/common/snackbarService';

import { ISpacePromotionsTableProps, SpacePromotionTableItem } from './types';

export const SpacePromotionsTable = (props: ISpacePromotionsTableProps) => {
  const { app, isLoading, isUpdating, config, segment, tableItems, onClose } = props;

  const [genericTableData, setGenericTableData] = useState<SpacePromotionTableItem[]>([]);
  const [processing, setProcessing] = useState(isLoading);

  const [query, setQuery] = useGenericTablePersistedQuery({ key: PersistedQueryKeys.SpacePromotions });
  const [tableQuery, setTableQuery] = useTableQueryChange({
    tableData: tableItems as unknown as IGenericTableEntity[],
    tableEntriesSettingHandler: setGenericTableData as unknown as (items: IGenericTableEntity[]) => void,
    tableQuery: query,
    isTableProcessingSettingHandler: setProcessing,
  });

  useEffect(() => {
    setTableQuery(query);
  }, [query, setTableQuery]);

  const getURLByItem = useCallback(
    ({ className, urlPathId }: SpacePromotionTableItem) => {
      if (!app?.routeName) {
        return;
      }
      if (className === GameEventWithVersion.ClassName) {
        return `/apps/${app.routeName}/liveops/events/${urlPathId}`;
      }
      if (className === FlowWithVersion.ClassName) {
        return `/apps/${app.routeName}/liveops/flows/${urlPathId}`;
      }
      if (className === ExperimentWithVersion.ClassName) {
        return `/apps/${app.routeName}/analytics/experiments/${urlPathId}`;
      }
    },
    [app?.routeName]
  );

  const handleCopyError = () => {
    snackbarService.error('Could not get entity URL.');
  };

  const handleNameClick = useCallback(
    (item: SpacePromotionTableItem) => async (event: SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();

      const relativePath = getURLByItem(item);
      if (!relativePath) {
        handleCopyError();
        return;
      }
      try {
        await navigator.clipboard.writeText(`${window.location.origin}${relativePath}`);
        snackbarService.info('Link copied.');
      } catch {
        handleCopyError();
      }
    },
    [getURLByItem]
  );

  const columns: ITableColumn<SpacePromotionTableItem>[] = useMemo(
    () => [
      {
        label: 'Name',
        queryKey: 'name',
        filterField: 'name',
        filterType: TextFilter.ClassName,
        sortField: 'name',
        render: (item) => (
          <Tooltip title='Copy Link'>
            <Link href={getURLByItem(item)} sx={{ color: 'text.primary' }} onClick={handleNameClick(item)}>
              {item.name}
            </Link>
          </Tooltip>
        ),
      },
      {
        label: 'Version',
        queryKey: 'version',
        filterField: 'version',
        filterType: TextFilter.ClassName,
        sortField: 'version',
        render: (item) => item.version,
      },
    ],
    [getURLByItem, handleNameClick]
  );

  const handleQueryChange = (qc: ITableChangeQuery) => {
    setQuery(qc.query);
  };

  const goToItem = (event: SyntheticEvent, item: SpacePromotionTableItem) => () => {
    const url = getURLByItem(item);
    if (url) {
      navigateToURL({ url, event });
    }
  };

  const handleRowClick = (event: SyntheticEventWithMetaKey, item: SpacePromotionTableItem) => {
    if (event.metaKey) {
      goToItem(event, item)();
    } else {
      onClose(goToItem(event, item));
    }
  };

  const configVersionURL = app?.name && config !== undefined ? `/apps/${app.name}/configuration/${config}` : undefined;

  const goToConfigVersion = (event: SyntheticEventWithMetaKey) => () => {
    event.preventDefault();
    if (configVersionURL !== undefined) {
      navigateToURL({ url: configVersionURL, event });
    }
  };

  const handleConfigVersionClick = (event: SyntheticEvent) => {
    onClose(goToConfigVersion(event));
  };

  const segmentationURL = app?.name && segment !== undefined ? `/apps/${app.name}/analytics/segmentation` : undefined;

  const goToSegmentation = (event: SyntheticEventWithMetaKey) => () => {
    event.preventDefault();
    if (segmentationURL) {
      navigateToURL({ url: segmentationURL, event });
    }
  };

  const handleSegmentationClick = (event: SyntheticEventWithMetaKey) => {
    onClose(goToSegmentation(event));
  };

  if (isUpdating) {
    return <PageLoaderSpinner />;
  }

  return (
    <>
      {' '}
      {config !== undefined && (
        <Typography>
          Promoted config version:{' '}
          <Link href={configVersionURL} onClick={handleConfigVersionClick}>
            {config}
          </Link>
        </Typography>
      )}
      {segment !== undefined && (
        <Typography>
          Promoted segment version:{' '}
          <Link href={segmentationURL} onClick={handleSegmentationClick}>
            {segment}
          </Link>
        </Typography>
      )}
      <GenericTable
        data={genericTableData}
        columns={columns}
        pending={true}
        processing={processing}
        initialQuery={tableQuery}
        disableQueryShare={true}
        options={{
          withToolbar: false,
        }}
        onQueryChange={handleQueryChange}
        onRowClick={handleRowClick}
      />
    </>
  );
};
