import { FC, memo, useContext } from 'react';

import { ProgressIndicatorStyled } from './styles';
import { ProgressIndicatorContext } from './ProgressIndicatorContext';

export const ProgressIndicator: FC = memo(() => {
  const isProgressIndicatorVisible = useContext<boolean>(ProgressIndicatorContext);

  return isProgressIndicatorVisible ? <ProgressIndicatorStyled color='secondary' /> : null;
});
