import { useCallback, useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useIsMutating, useQueryClient } from '@tanstack/react-query';

import { spacePromotionsCleanKey, useAppSpacePromotions, useExperimentsQueryByIds, useFlowsQueryByIds } from '/api';
import { appToolkit } from '/store';
import { useGameEventsQueryByIds } from '/api';

import { getPromotionsInitialValues, getSpacePromotionFromEntityWithVersion } from './helpers';
import { ISpacePromotionsProps, SpacePromotionTableItem } from './types';
import { SpacePromotionsActions } from './SpacePromotionsActions';
import { SpacePromotionsTable } from './SpacePromotionsTable';
import { SpacePromotionsHeader } from './styles';

export const SpacePromotions = ({ id, name, onClose }: ISpacePromotionsProps) => {
  const app = useSelector(appToolkit.selectors.app);

  const queryClient = useQueryClient();

  const isRemoved = useRef(false);

  const { data: promotions, isLoading, keys } = useAppSpacePromotions(id, { enabled: !isRemoved.current });

  const { events, flows, experiments } = promotions ?? getPromotionsInitialValues();

  const { events: eventBriefs } = useGameEventsQueryByIds(events.map(({ id: eventId }) => eventId.id));
  const { flows: flowBriefs } = useFlowsQueryByIds(flows.map(({ id: flowId }) => flowId.id));
  const { experiments: experimentBriefs } = useExperimentsQueryByIds(experiments.map(({ id: expId }) => expId.id));

  const [config, setConfig] = useState<number>();
  const [segment, setSegment] = useState<number>();
  const [tableItems, setTableItems] = useState<SpacePromotionTableItem[]>([]);

  const isClearing = useIsMutating({ mutationKey: spacePromotionsCleanKey });

  useEffect(() => {
    setConfig(promotions?.config);
  }, [promotions?.config]);

  useEffect(() => {
    setSegment(promotions?.segment);
  }, [promotions?.segment]);

  useEffect(() => {
    setTableItems([
      ...events.map((ev) => getSpacePromotionFromEntityWithVersion(ev, eventBriefs)),
      ...flows.map((f) => getSpacePromotionFromEntityWithVersion(f, flowBriefs)),
      ...experiments.map((exp) => getSpacePromotionFromEntityWithVersion(exp, experimentBriefs)),
    ]);
  }, [events, eventBriefs, flows, flowBriefs, experiments, experimentBriefs]);

  const handleClear = useCallback(() => {
    isRemoved.current = true;
    queryClient.removeQueries(keys);
    setTableItems([]);
    setConfig(undefined);
    setSegment(undefined);
  }, [queryClient, keys]);

  const isEmpty = !isLoading && config === undefined && segment === undefined && tableItems.length === 0;

  const isEditable = !!app && !isEmpty && !isClearing && !isLoading;

  const placeholder = !app ? 'Please, select application' : 'Nothing is promoted yet';

  return (
    <>
      <SpacePromotionsHeader>
        <Typography variant='h6'>Promotions</Typography>
        {isEditable && <SpacePromotionsActions id={id} name={name} promotions={promotions} onClear={handleClear} />}
      </SpacePromotionsHeader>
      {(isEmpty || !app) && <Typography sx={{ pb: 1 }}>{placeholder}</Typography>}
      {!isEmpty && !!app && (
        <SpacePromotionsTable
          isLoading={isLoading}
          isUpdating={!!isClearing}
          segment={segment}
          config={config}
          tableItems={tableItems}
          app={app}
          onClose={onClose}
        />
      )}
    </>
  );
};
