import { filter } from 'rxjs/operators';

import { store, appToolkit } from '/store';

import { buzzerNotifications$ } from './buzzerNotifications';

export const buzzerAppNotifications$ = buzzerNotifications$.pipe(
  filter((notification) => {
    const state = store.getState();
    const appID = appToolkit.selectors.appID(state);
    return !!appID && 'appID' in notification && notification.appID === appID.id;
  })
);
