import { useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';

import { snackbarService } from '/common/snackbarService';
import { FCWithChildren } from '/common/models';
import { notificationsToolkit } from '/store';

import { ISnackbarProps } from './types';
import { SNACKBAR_AUTO_HIDE_DURATION_MS } from './constants';

const SnackbarContainer: FCWithChildren = (provider) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const sub = snackbarService.snackbars$.subscribe((props: ISnackbarProps) => {
      // Setting up new snackbars as un-read for notification(snackbar history)
      dispatch(notificationsToolkit.actions.setUnreadNotifications(true));

      enqueueSnackbar(props.msg, {
        ...props,
        variant: props.type,
        autoHideDuration: SNACKBAR_AUTO_HIDE_DURATION_MS,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        action: props.action,
        persist: props.persist,
      });
    });

    return () => sub.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{provider.children}</>;
};

export default SnackbarContainer;
